import { Component, computed, Injector, OnDestroy, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DepartureDescriptionComponent } from 'src/components/departure-description/departure-description.component';
import { BaseImports } from 'src/libs/base-imports';
import { Subscription } from 'rxjs';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TicketCreateDo } from 'src/dos/ticket-create.do';
import { DatedJourneyDto } from 'src/dtos/dated-journey/dated-journey.dto';
import { JourneyDo } from 'src/dos/journeys/journeys.do';
import { DatedJourneyDetailsViewDto } from 'src/dtos/dated-journey/dated-journey-details-view.dto';
import { TicketCreateDto } from 'src/dtos/ticket-create.dto';

@Component({
  selector: 'reservation-create',
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, FormsModule, DepartureDescriptionComponent],
  templateUrl: './reservation-create.page.html',
  styleUrls: ['./reservation-create.page.scss']
})
export class ReservationsCreatePage extends BaseImports implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  ticket: WritableSignal<TicketCreateDo>;
  rideDescription: Signal<JourneyDo>;
  form = this.formBuilder.group({
    Quantity: [<number>1, [Validators.required, Validators.max(5)]],
  });

  constructor(private injector: Injector) {
    super(injector);
    const storedTicket = this.storageService.getTicket();
    if (storedTicket) {
      this.ticket = signal<TicketCreateDo>(storedTicket);
    }
    else {
      this.ticket = signal<TicketCreateDo>(new TicketCreateDo());
    }

    this.rideDescription = computed(() => { return new JourneyDo(<DatedJourneyDto>this.ticket()); });
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  onSubmit() {
    //this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    const data: TicketCreateDto = <TicketCreateDto>this.form.value;
    data.OrderCode = this.ticket().OrderCode;

    data.OriginalStartPointId = data.StartPointId;
    data.OriginalEndPointId = data.EndPointId;


    this.subscriptions.push(this.webapiReservationService.createTicketReservation(data).subscribe((res) => {
      if (res.IsSuccess) {
        TicketCreateDo.mapTicketResponse(res, this.ticket());
        this.ticket().Quantity = data.Quantity;
        this.ticket().Email = data.Email;
        this.ticket().IsOpenRoundtripJourney = data.IsOpenRoundtripJourney;
        if (data.RoundtripDatedJourneyId) {
          this.ticket().RoundtripDatedJourneyId = data.RoundtripDatedJourneyId
        }

        this.storageService.setTicket(this.ticket());
        this.routerService.navigate("my-tickets");
      }
      else {
        this.commonService.alertError(res.Reason)
      }
    }));
  }
}
