import { CommonModule } from '@angular/common';
import { Component, OnInit, Injector, OnDestroy, signal, computed, Signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseImports } from "src/libs/base-imports";
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { DatedJourneyDto } from 'src/dtos/dated-journey/dated-journey.dto';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from 'src/libs/date-providers';
import { debounceTime, distinctUntilChanged, map, Observable, OperatorFunction, Subscription } from 'rxjs';
import { PointViewDto } from 'src/dtos/common/point-view.dto';
import { PointDto } from 'src/dtos/points.dto';
import { DepartureDescriptionComponent } from 'src/components/departure-description/departure-description.component';
import { JourneyDo } from 'src/dos/journeys/journeys.do';
import { TicketCreateDo } from 'src/dos/ticket-create.do';
@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, NgbTypeaheadModule, NgbDatepickerModule, DepartureDescriptionComponent],
  selector: 'journeys',
  templateUrl: './journeys.page.html',
  styleUrls: ['./journeys.page.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class JourneysPage extends BaseImports implements OnInit, OnDestroy {
  lang!: Signal<string>;
  subscriptions: Subscription[] = [];
  submitted = false;
  data = signal<DatedJourneyDto[]>([]);

  startPoints: PointViewDto[] = [];
  endPoints: PointViewDto[] = [];

  startPointName: string = "";
  endPointName: string = "";
  prevDate: Date = new Date();//DateDo = new DateDo("", new Date());
  currentDate: Date = new Date();//DateDo = new DateDo("", new Date());
  nextDate: Date = new Date();//DateDo = new DateDo("", new Date());

  loyaltyBalance?: number = 0;

  formatter = (result: { Caption: string }) => result.Caption;

  form = this.formBuilder.group({
    startPoint: [<undefined | PointDto>undefined, Validators.required],
    endPoint: [<undefined | PointViewDto>undefined, Validators.required],
    date: [new Date(), Validators.required],
  });

  inProgress: boolean = false;

  constructor(private injector: Injector) {
    super(injector);
    this.subscriptions.push(this.route.params.subscribe(params => {
      const startPointId = <number>params['startpoint'];
      const endPointId = <number>params['endpoint'];
      const date = params['date'];

      this.form.controls['date'].setValue(new Date(date));
      this.webapiCommonService.getPoints().subscribe((res) => {
        var startPoint = res.find(x => x.Id == startPointId);
        if (startPoint) {
          this.startPointName = startPoint.Caption;
          this.form.controls['startPoint'].setValue(startPoint);
        }
        var endPoint = res.find(x => x.Id == endPointId);
        if (endPoint) {
          this.endPointName = endPoint.Caption;
          this.form.controls['endPoint'].setValue(endPoint);
        }

        this.startPoints = res.filter(x => x.IsStartPoint);
        this.endPoints = res.filter(x => x.IsEndPoint);
        this.getJourneys();
      });
    }));
    this.lang = computed(() => {
      return this.localizationService.currentLanguage().LanguageCode_2;
    });
  }

  ngOnInit() {
    if (this.authenticationService.isAuthenticated()) {
      this.subscriptions.push(this.webapiIdentityService.getProfile().subscribe((res) => {
        this.loyaltyBalance = res.LoyaltyBalance;
      }));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  getJourneys() {
    var dateStr = this.commonService.formatDate(this.form.controls['date'].value!);
    this.inProgress = true;
    this.subscriptions.push(this.webapiJourneysService.getJourneys(this.form.controls['startPoint'].value?.Id!, this.form.controls['endPoint'].value?.Id!, dateStr).subscribe((res) => {
      this.data.set(res.map((x) => {
        var datedJourney: DatedJourneyDto = {
          ...x,
          StartPointName: this.getPointName(x.StartPointId),
          EndPointName: this.getPointName(x.EndPointId),
        };
        return datedJourney;
      }));

      this.inProgress = false;
    }));

    this.setDates(this.form.controls['date'].value!);
  }

  buy(journey: DatedJourneyDto) {
    var currentTicketReservation = this.storageService.getTicket();
    if (currentTicketReservation && journey.DatedJourneyId != currentTicketReservation.DatedJourneyId) {
      this.storageService.clearTicket();
    }
    this.storageService.setTicket(<TicketCreateDo>journey);
    this.routerService.navigate('ticket-create');
  }

  setDates(date: Date, search: boolean = false) {
    this.currentDate = date;
    this.prevDate = new Date(date.valueOf() - 1000 * 60 * 60 * 24);
    this.nextDate = new Date(date.valueOf() + 1000 * 60 * 60 * 24);
    this.form.controls['date'].setValue(date);

    if (search) {
      this.getJourneys();
    }
  }

  filterPoints(points: PointViewDto[], trem: string) {
    return <PointViewDto[]>points.map(x => {
      return {
        ...x,
        Index: this.getIndexCaptionMatch(trem, x)
      }
    }).filter(v => v.Index > -1).sort((a, b) => { return a.Index - b.Index }).slice(0, 10);
  }

  getIndexCaptionMatch(term: string, point: PointViewDto): number {
    var caption = this.commonService.normalizeLatinText(point.Caption).toLowerCase();
    var abbr = this.commonService.normalizeLatinText(point.Abbreviation).toLowerCase();
    var term = this.commonService.normalizeLatinText(term).toLowerCase();

    if (caption == term || abbr == term) {
      return -1;
    }

    var captionIndex = caption.indexOf(term);
    if (captionIndex == -1) {
      return Number.MIN_VALUE;
    }
    else {
      return captionIndex;
    }
  }

  startPointsAutocomplete: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) =>
    text$.pipe(debounceTime(200), distinctUntilChanged(), map(term => term.length < 0 ? []
      : this.filterPoints(this.startPoints, term))
    )


  endPointsAutocomplete: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) =>
    text$.pipe(debounceTime(200), distinctUntilChanged(), map(term => term.length < 1 ? []
      : this.filterPoints(this.endPoints, term))
    )

  swapPoints() {
    var p = this.form.controls['endPoint'].value;
    this.form.controls['endPoint'].setValue(this.form.controls['startPoint'].value);
    this.form.controls['startPoint'].setValue(p);
  }

  getPointName(id: number) {
    var sp = this.startPoints.find(x => x.Id == id);
    if (!sp) {
      var ep = this.endPoints.find(x => x.Id == id);
      return ep ? ep.Caption : '';
    }

    return sp.Caption;
  }

  mapDatedJourney(data: DatedJourneyDto) {
    return new JourneyDo(data);
  }

  showLoginModal(smallScreen: boolean = false) {
    this.sharedService.broadcast(this.constants.EV_SHOW_LOGIN_MODAL, smallScreen);
  }
}
