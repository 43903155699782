<div class="title margin-bottom-30">
    <a href="./#notes" class="text-white text-uppercase">
        <h1 class="px-2">{{ 'think_ahead_travel_cheaper' | translate}}</h1>
    </a>
</div>

<div class="container">
    <form [formGroup]="form">
        <div class="row justify-content-center margin-bottom-30">
            <div class="col-12 col-lg-10 px-3 justify-content-md-center row">
                <div class="col-9 col-md-3 my-2 my-md-1">
                    <div class="input-group">
                        <span class="input-group-text  pe-1">
                            <img src="../../assets/icons/flag.svg" width="20" class="margin-right-5">
                            <span class="text-capitalize">
                                {{ "from" | translate }}</span></span>
                        <input type="text" class="form-control fw-bolder text-start px-1" formControlName="startPoint"
                            [ngbTypeahead]="startPointsAutocomplete" [resultFormatter]="formatter"
                            [inputFormatter]="formatter" [editable]="false">
                        <!-- <span class="error-messages" *ngIf="submitted && f(form, 'startPoint')">{{ "required_field" | translate }}</span> -->
                    </div>
                </div>
                <div class="col text-center px-0 my-auto">
                    <!-- <i (click)="swapPoints()" class="fa fa-retweet cursor-pointer" aria-hidden="true"></i> -->
                    <img class="cursor-pointer" (click)="swapPoints()" src="../../assets/icons/swap.svg" width="22">
                </div>
                <div class="col-12 col-md-3 my-2 my-md-1">
                    <div class="input-group">
                        <span class="input-group-text pe-1">
                            <img src="../../assets/icons/flag.svg" width="20" class="margin-right-5">
                            <span class="text-capitalize">
                                {{ "to" | translate }}</span>
                        </span>
                        <input type="text" class="form-control px-1 fw-bolder text-start" formControlName="endPoint"
                            [ngbTypeahead]="endPointsAutocomplete" [resultFormatter]="formatter"
                            [inputFormatter]="formatter" [editable]="false">
                        <!-- <span class="error-messages" *ngIf="submitted && f(form, 'endPoint')">{{ "required_field" | translate }}</span> -->
                    </div>
                </div>
                <div class="col px-0 d-none d-md-flex my-1"></div>
                <div class="col-12 col-md-3 my-2 my-md-1">
                    <div class="input-group col-3">
                        <span class="input-group-text pe-1">
                            <img src="../../assets/icons/calendar.svg" width="20" class="margin-right-5"><span
                                class="">{{ "date" | translate }}</span>
                        </span>
                        <input type="text" ngbDatepicker #d="ngbDatepicker" formControlName="date"
                            class="form-control px-1 fw-bolder text-start" (click)="d.toggle()" readonly>
                    </div>
                </div>
                <div class="col col-md-2 d-flex justify-content-center justify-content-md-end my-3 my-md-1 d-md-block">
                    <button type="button" class="btn btn-primary py-1" (click)="getJourneys()">
                        <img class="margin-right-5" src="../../assets/icons/search.svg" width="18">
                        {{ 'search' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>
    <div class="row justify-content-center margin-bottom-30">
        <div class="col-3 col-md-2 text-center px-1 journeys-first-date-tab" (click)="setDates(prevDate!, true)">
            {{prevDate | date: 'ccc, dd. MMM':'':lang()}}
        </div>
        <div class="col-3 col-md-2 text-center px-1  fw-bolder journeys-second-date-tab text-primary">
            {{currentDate | date: 'ccc, dd. MMM':'':lang()}}
        </div>
        <div class="col-3 col-md-2 text-center px-1 journeys-third-date-tab" (click)="setDates(nextDate!, true)">
            {{nextDate | date: 'ccc, dd. MMM':'':lang()}}</div>
    </div>

    <div *ngFor="let journey of data()" class="row  d-flex px-2 px-md-5 justify-content-center  ">
        <div class="col-lg-9 mx-md-5 margin-bottom-5 p-3 journeys-card min-height-90 pb-2">
            <div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
                <div class="d-flex flex-column justify-content-between align-items-center w-75">
                    <departure-description-component [datedJourney]="mapDatedJourney(journey)"
                        class="journeys-ride-description w-100"></departure-description-component>
                    <div class="font-12 padding-top-10 fst-italic pe-3" *ngIf="storageService.isAgency()">
                        {{"available_seats_number"| translate}}:
                        <span class="fw-bolder"> {{journey.SeatsAvailable}}</span>
                    </div>
                </div>
                <div class="d-flex flex-row justify-content-between w-100">
                    <div
                        class="d-flex flex-row flex-lg-column text-center variable-font-size-20 ms-sm-5 mx-md-auto mt-3 mt-md-0">
                        <span class="fs-5 "
                            [ngClass]="{'text-primary fw-bolder': (journey.AdditionDiscount && journey.AdditionDiscount > 0)}">&nbsp;{{journey.Price}}
                            rsd&nbsp;</span>
                        <s class="journey-original-price variable-font-size-16 align-self-center"
                            *ngIf="journey.AdditionDiscount && journey.AdditionDiscount > 0"><span
                                class="m-1">&nbsp;{{journey.OriginalPrice}} rsd&nbsp;</span></s>
                    </div>
                    <div class="d-flex justify-content-end">
                        <div class="text-center align-self-end align-self-md-center d-flex"
                            *ngIf="journey.SeatsAvailable && journey.SeatsAvailable > 0">
                            <button type="button"
                                class="btn btn-primary text-uppercase order-2 me-0 me-md-2 ms-2 ms-md-2"
                                (click)="buy(journey)">{{ "buy" | translate}}</button>
                            <!-- href="./reservation-create" -->
                            <a (click)="commonService.alert('Opcija rezervacije uskoro će biti dostupna')"
                                class="ms-md-2 ms-0 me-2 me-md-2 align-self-center order-1 order-md-2 first-letter-uppercase cursor-pointer">
                                {{'book' | translate}}</a>
                        </div>
                        <div class="text-center align-self-end align-self-md-center d-flex "
                            *ngIf="!journey.SeatsAvailable || journey.SeatsAvailable == 0">
                            <a class="text-primary">{{'no_available_seats' | translate}}</a>
                        </div>
                    </div>

                </div>
            </div>
            <div class="text-right font-10 mt-2 d-none d-md-block"
                *ngIf="authenticationService.isAuthenticated() && loyaltyBalance != null">
                {{ "current_balance_on_the_loyalty_card" | translate }} <span
                    class="text-primary">{{loyaltyBalance}}</span> RSD.</div>
            <div class="text-right font-10 mt-2
             d-none d-md-block" *ngIf="!authenticationService.isAuthenticated()">
                <a class="text-primary fw-bolder cursor-pointer" (click)="showLoginModal()">{{'login' | translate }}
                </a>
                <span [innerHtml]="'to_advantage_of_benefits_of_express+_program' | translate"></span>
            </div>

        </div>
    </div>

    <div class="text-center text-primary fw-bold mt-3 font-18" *ngIf="data().length == 0 && !inProgress">
        {{'no_results_for_selected_date' | translate}}
    </div>
</div>

<div class="journeys-info-section d-md-none" *ngIf="!authenticationService.isAuthenticated() || loyaltyBalance != null">
    <div class="journeys-info" *ngIf="authenticationService.isAuthenticated() && loyaltyBalance != null">
        {{ "current_balance_on_the_loyalty_card" | translate }} <span class="fw-bolder">{{loyaltyBalance}}</span> RSD.
    </div>
    <div class="journeys-info" *ngIf="!authenticationService.isAuthenticated()">
        <a class="text-primary fw-bolder cursor-pointer" (click)="showLoginModal(true)">{{'login' | translate }} </a>
        <span [innerHtml]="'to_advantage_of_benefits_of_express+_program' | translate"></span>
    </div>
</div>