export class SignUpDto {
  Username?: string;
  Password?: string;
  Email?: string;
  Phone?: string;
  Address?: string;
  Passport?: string;
  BirthDate?: string;
  WantLoyaltyProgram?: boolean;
  PIB?: string;

  constructor() {}
}
